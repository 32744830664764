import { createTheme } from '@mui/material/styles';
import { RaThemeOptions } from 'react-admin';

import './styles/fonts.css';

// Blue
export const BLUE050 = '#E7E8F0';
export const BLUE400 = '#5B6099';
export const BLUE700 = '#00B0B9';
export const EXOS_BLUE = '#201C52'; // Blue 800

// Bright blue
export const BRIGHTBLUE400 = '#00DEEA';
export const EXOS_BRIGHT_BLUE = '#02E6E9'; // Bright blue 700

// Red
export const EXOS_RED = '#E68283'; // Red 300
export const STATUS_ERROR = '#BD3732'; // Red 900

// Orange
export const EXOS_ORANGE = '#ECBE85'; // Orange 200
export const ORANGE400 = '#E39131'; // Orange 400
export const ORANGE700 = '#D46615'; // Orange 700

// Gray (Steel)
export const EXOS_GRAY = '#F2F2F2'; // Gray 050
export const GRAY100 = '#E9E9E9';
export const SECONDARY_DISABLED = '#D9D9D9'; // Background divider, secondary disabled, gray 200
export const GRAY300 = '#C4C4C4';
export const GRAY400 = '#9D9D9D';
export const GRAY500 = '#7B7B7B';
export const GRAY600 = '#555555';
export const GRAY700 = '#434343';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const BLACK3 = '#38424A';

// Aquamarine
export const GREEN050 = '#E0F9F0';
export const AQUAMARINE = '#00DBA8'; // Green 300
export const GREEN400 = '#00D294';
export const GREEN600 = '#00B774';
export const GREEN800 = '#009258';
export const GREEN900 = '#007140';

// Non-palette colours
export const SECONDARY_HOVER_GRAY = '#F0F0F0';
export const PRIMARY_HOVER_GRAY = '#2D353D';
export const DISABLED_GRAY = '#6F6F6F'; // Text disabled, icon disabled
export const BORDER_GRAY = '#E3E3E3'; // Border
export const ICON_SECONDARY = '#62737D';
export const BACKGROUND = '#FAFAFB';
export const HOVER = '#EFF0F7';
export const BLACK2 = '#201C21'; // Text primary, icon primary
export const PLACEHOLDER = '#677582'; // Text placeholder

const customTheme = createTheme({
  palette: {
    primary: {
      main: AQUAMARINE,
    },
    secondary: {
      main: EXOS_BLUE,
      contrastText: WHITE,
    },
    success: {
      main: AQUAMARINE,
    },
    error: {
      main: STATUS_ERROR,
    },
    background: {
      default: SECONDARY_HOVER_GRAY,
      paper: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: BLACK,
    },
  },
  typography: {
    fontFamily: 'inter', // use inter font everywhere except titles
    h1: {
      fontFamily: 'poppins',
      fontSize: 60,
      fontWeight: 400,
    },
    h2: {
      fontFamily: 'poppins',
      fontSize: 48,
      fontWeight: 400,
    },
    h3: {
      fontFamily: 'poppins',
      fontSize: 34,
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'poppins',
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'poppins',
      fontSize: 20,
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'poppins',
      fontSize: 16,
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: 'poppins',
      fontSize: 16,
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'inter',
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'inter',
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontFamily: 'inter',
      fontSize: 16,
      fontWeight: 600,
      color: EXOS_BLUE,
      textTransform: 'uppercase',
    },
    overline: {
      fontFamily: 'inter',
      fontSize: 12,
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: 'Poppins',
          fontWeight: 600,
          fontSize: 14,
          color: 'black',
          opacity: '0.54',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: WHITE,
        },
        root: {
          color: GRAY400,
          '&$active': {
            color: EXOS_BLUE,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&$focused $notchedOutline': {
            borderColor: EXOS_BLUE,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: EXOS_BLUE,
          },
        },
      },
    },
    // The MuiContainer is the container for the entire screen. Added this to the theme so that we don't have to specify a background color every time we create a new screen.
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: BORDER_GRAY,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 14,
          height: 14,
          borderRadius: 7,
          backgroundColor: AQUAMARINE,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          color: EXOS_BLUE,
          backgroundColor: SECONDARY_HOVER_GRAY,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
    },
  },
});

const theme = {
  ...customTheme,
  components: {
    RaTabbedShowLayout: {
      styleOverrides: {
        root: {
          '& .RaTabbedShowLayout-content': {
            padding: 16,
          },
        },
      },
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          '& .RaSimpleShowLayout-stack': {
            padding: 16,
          },
        },
      },
    },
    RaListToolbar: {
      styleOverrides: {
        root: { marginBottom: 8 },
      },
    },
  },
};

export default theme as RaThemeOptions;
