import { Reference, relayStylePagination } from '@apollo/client/utilities';
import { getCurrentToken } from '@teamexos/fit-shared';
import type { ClientOptions } from '@teamexos/prince-sdk';

import fragments from '../graphql/fragments.json';

export const mergeNodes = (existing: Reference[], incoming: Reference[]) => {
  if (!existing) {
    return incoming;
  }

  if (!incoming) {
    return existing;
  }

  const existingRefs = existing.map(({ __ref }) => __ref);

  // dedupe any incoming and preserve order
  const incomingDeduped = incoming.filter(
    ({ __ref }) => !existingRefs.includes(__ref),
  );

  return [...existing, ...incomingDeduped];
};

export const mergeEdges = (
  existing: Array<{ node: Reference }>,
  incoming: Array<{ node: Reference }>,
) => {
  if (!existing) {
    return incoming;
  }

  if (!incoming) {
    return existing;
  }

  const existingRefs = existing.map(({ node: { __ref } }) => __ref);

  // dedupe any incoming chats and preserve order
  const incomingDeduped = incoming.filter(
    ({ node: { __ref } }) => !existingRefs.includes(__ref),
  );

  return [...existing, ...incomingDeduped];
};

export const clientOptions: ClientOptions = {
  getToken: async () => {
    const tokens = await getCurrentToken();
    return tokens?.idToken?.toString();
  },
  clientUrl:
    process.env.GATSBY_API_URL || 'https://graphql.dev.prince.ex-os.net/',
  apolloClientOptions: {
    name: 'shared-platform-admin',
    version: process.env.GATSBY_COMMIT_ID ?? 'unknown',
    connectToDevTools: process.env.NODE_ENV === 'development',
  },
  possibleTypes: fragments.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        usePush: {
          read: (existing: boolean | undefined) =>
            typeof existing === 'boolean' ? existing : true,
        },
      },
    },
    User: {
      fields: {
        chatRooms: relayStylePagination(['filters', 'orderBy']),
        auditLogs: relayStylePagination(),
        activity: relayStylePagination(['filters']),
        currentPractice: {
          merge: true,
        },
      },
    },
    Client: {
      fields: {
        coaches: relayStylePagination(),
      },
    },
    CoachLocation: {
      fields: {
        coaches: relayStylePagination(),
      },
    },
    CoachData: {
      fields: {
        coachUsers: relayStylePagination(),
      },
    },
    ActivityConnection: {
      fields: {
        nodes: {
          merge: (existing, incoming, options) => {
            // we only want to merge nodes if is pagination
            // otherwise we want the incoming, because filters may change
            if (!options?.variables?.input?.after) {
              return incoming;
            }

            return mergeNodes(existing, incoming);
          },
        },
      },
    },
    UserChatRoomsConnection: {
      fields: {
        // Required for the original member list (still used by Perform)
        nodes: {
          merge: mergeNodes,
        },
        // Required for member list v1 used by fit
        edges: {
          merge: mergeEdges,
        },
      },
    },
    ChatRoom: {
      fields: {
        messages: relayStylePagination(),
      },
    },
    ClientUsersConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    AuditLogsConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    LocationCoachesConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    CoachUsersConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    ChatRoomMessagesConnection: {
      fields: {
        nodes: {
          merge(existing: Reference[], incoming: Reference[], { readField }) {
            const nodes: Record<string, Reference> = {};

            (existing ?? []).forEach((r) => {
              const key = readField('id', r);
              if (typeof key === 'string' || typeof key === 'number') {
                nodes[key] = r;
              }
            });
            (incoming ?? []).forEach((r) => {
              const key = readField('id', r);
              if (typeof key === 'string') {
                nodes[key] = r;
              }
            });

            // Sort messages by id
            return Object.values(nodes).sort(
              (a, b) =>
                Number(readField('id', a) ?? '') -
                (Number(readField('id', b)) ?? ''),
            );
          },
        },
      },
    },
  },
  debug: process.env.NODE_ENV === 'development',
};
